<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="8">
        <CCardGroup>
          <CCard class="p-3">
            <CCardBody>
              <CForm @submit="onSubmit">
                <H2>
                  <CImg src="img/logo-icon.png" width="20%" class="mb-1 mr-1"/> <strong>Drive</strong>
                </H2>

                <CInput
                  v-model="userId"
                  placeholder="아이디"
                  autocomplete="off"
                  required
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  v-model="userPwd"
                  placeholder="비밀번호"
                  type="password"
                  autocomplete="off"
                  aria-required="true"
                  requied
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>

                </CInput>
                <CRow>
                  <CCol col="12" class="text-right">
                    <CButton type="submit" color="warning" class="px-4" :disabled="isProcessing || userPwd.length<6" block>
                      <CSpinner size="sm" type="grow" v-if="isProcessing"></CSpinner>
                      <strong>LOGIN</strong>
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter class="bg-danger text-white" v-show="message!==''">
              <strong>{{message}}</strong>
            </CCardFooter>
          </CCard>
          <CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-md-down-none"
            body-wrapper
            >
            <CCardBody>
              <div>
                <h5 class="font-weight-bolder">탁송 시스템 <CBadge color="info"><i>v0.3 Beta</i></CBadge></h5>
                <hr/>
                <h5 class="text-warning">관계자 외 접근을 제한합니다.</h5>
                <h6 class="text-danger text-sm">사용자의 모든 활동은 기록됩니다.</h6>
                <hr/>

                <i><strong>Chungbang Co.Ltd.</strong></i>
              </div>
            </CCardBody>
          </CCard>


        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
  </div>
</template>

<script>
import {alertError, alertWarn} from '@/common/utils';


export default {
  name: 'Login',
  props: {
    message: { type: String, default: '' },
    routeTo: { type: String, default: '' },
    mode: { type: String, default: '' },
    query: { type: Object, default: null },
  },
  data(){
    return {
      userId: '',
      userPwd: '',
      isProcessing: false,
      // userId: "test-admin"  ,
      // userPwd: "test-admin-password",

    }
  },
  async created(){
    console.log("####### Login.created() message--->", this.message);

    // if( this.$store.state.isAuth ){
    //     await this.redirect();
    // }

    if( localStorage.user ){
      const localUser = JSON.parse( localStorage.user );
      console.log( "######## Login.created() ------------ localStorage.user: ", localUser );
      try{
        const rs = await this.$store.dispatch('setLoginState', localUser );
        if(rs) await this.redirect();

      }catch(err){
        console.log( "[CREATED] -------------- err: ", err );
      }
    }

  },
  beforeMount(){},
  mounted() {
    console.log( "params.mode    --->", this.mode );
    console.log( "params.routeTo --->", this.routeTo );
    console.log( "params.query   --->", this.query );
  },
  methods: {
    async onSubmit(evt){
      evt.preventDefault();
      console.log("------------------> Login.onSubmit()..............");
      try{
        this.isProcessing = true;
        const rs = await this.$store.dispatch('LOGIN', {userId:this.userId, userPwd:this.userPwd });

        console.log( "---- Login.onSubmit ......$store.LOGIN result: ", rs);
        // console.log( "---- Login.onSubmit .............store.isAuth: ", this.$store.state.isAuth);

        if( rs.code===200 ){
          if(this.$store.state.isAuth) {
            console.log( "after --------------if--------- isAuth ",this.$store.state.isAuth );
            await this.$router.push({ name: 'Dashboard'  }, () => { }, () => { });
          }
        }else if( rs.code===3209 ){
          await alertWarn( this.$bvModal, "비밀번호가 만료 되었습니다. 변경이 필요합니다.", "비밀번호 만료" );
          await this.$router.push({name: "PasswordReset", params: {userId: this.userId }});
        }else if( rs.code===3201){
          await alertWarn( this.$bvModal, "비밀번호가 초기화 되었습니다. 변경이 필요합니다.", "비밀번호 초기화" );
          await this.$router.push({name: "PasswordReset", params: {userId: this.userId }});
        }else if(rs.code===290){
          await alertWarn(this.$bvModal,  "중복 로그인 입니다.이전 세션은 로그아웃 됩니다.","중복 로그인 경고");
          await this.redirect();
        }else{
          console.log('login else case ----> ', rs);
          await alertError(this.$bvModal, rs.message, rs.code);
          return false;
        }
      }catch(err){
        console.log( "onSubmit result----------------err:", err );
        await alertError( this.$bvModal, err.message, "9999" );

      }finally{
        this.isProcessing = false;
      }


      // console.log("#########################===============> ", res);
    },
    async redirect() {
      try{
        console.log("Login.vue --- redirect() ===============> isAuth: ", this.$store.state.isAuth);

        //if( !this.$store.state.isAuth ) return;
        let routePath = '/dashboard';
        console.log("####### Login.redirect ----> " + routePath );
        await this.$router.push({ path: routePath  }, () => { }, () => { });
        // alertModal( this.$bvModal, {title: '로그인 성공', text:'환영합니다.', level: 'info'}, (ok)=>{});
      }catch(err){
        console.error(err);
      }
    },


  }
}
</script>
